//NB: this will be empty in the browser
import type { Locale } from "~/contexts";

import type { VariationGroup } from "../app.types";
import { invariant } from "./utils";

export const toProductUrl = (product?: { id?: string }) => {
  invariant(product && product.id, "toProductUrl:Product id is required");
  return `/product/${product.id}`;
};
export const toVariantURL = (variationGroup: VariationGroup) => {
  return `/${variationGroup.productId}.html?${Object.entries(
    variationGroup.variationValues || {},
  )
    .map(([key, value]) => `${key}=${value}`)
    .join("&")}`;
};

export function getDomainUrl(request: Request) {
  const host =
    request.headers.get("X-Forwarded-Host") ?? request.headers.get("host");
  if (!host) {
    throw new Error("Could not determine domain URL.");
  }
  const protocol = host.includes("localhost") ? "http" : "https";
  return `${protocol}://${host}`;
}

/**
 * Returns the application's origin.
 *
 * NOTE: This utility can only be used server-side after your application has been
 * initialized using the `_createApp` method (This happens in your /app/ssr.js file).
 *
 * @function
 * @returns {string} Returns the ORIGIN under which we are serving the page.
 * @example
 * import {getAppOrigin} from 'pwa-kit-react-sdk/utils/url'
 *
 * const url = `${getAppOrigin()}/path`
 */
export const getAppOrigin = () => {
  if (typeof window !== "undefined") {
    return window.location.origin;
  }

  // const APP_ORIGIN = process.env.APP_ORIGIN;

  if (!APP_ORIGIN) {
    throw new Error(`APP_ORIGIN env variable is not defined`);
  }

  return APP_ORIGIN;
};
export const getAppCallbackOrigin = () => {
  if (typeof window !== "undefined") {
    return window.location.origin;
  }

  // const APP_ORIGIN = process.env.APP_ORIGIN;

  if (!APP_CALLBACK_ORIGIN) {
    throw new Error(`APP_ORIGIN env variable is not defined`);
  }

  return APP_ORIGIN;
};

/**
 * A function that takes a path and qualifies it with the current host and protocol.
 * This function works on the client and on the server.
 *
 * @example
 * absoluteUrl(/women/dresses?color=black)
 *
 * // => http(s)://www.site.com/women/dresses?color=black
 * @param path
 * @returns {string|*}
 */
export const absoluteUrl = (path: string) => {
  return new URL(path, getAppOrigin()).toString();
};

/**
 * Modifies a given url by adding/updating query parameters.
 *
 * @param {string} url - The base url of the output url set.
 * @param {object} extraParams - A key values pairing used to add static search param values.
 * @returns {string} A URL with additional params
 * @example
 * import {rebuildPathWithParams} from '/path/to/utils/url'
 *
 * rebuildPathWithParams(
 *     '/en-GB/product/25501032M',
 *     {color: 'JJ2SKXX', size: 'MD'}
 * )
 *
 * // Returns
 * // '/en-GB/product/25501032M?color=JJ2SKXX&size=9MD'
 */
export const rebuildPathWithParams = (
  url: string,
  extraParams: { [key: string]: string | number },
) => {
  const [pathname, search] = url.split("?");
  const params = new URLSearchParams(search);

  // Apply any extra params.
  Object.keys(extraParams).forEach(key => {
    const value = extraParams[key];

    // 0 is a valid value as for a param
    if (!value && value !== 0) {
      params.delete(key);
    } else {
      params.set(key, value.toString());
    }
  });

  // Clean up any trailing `=` for params without values.
  const paramStr = params.toString().replace(/=&/g, "&").replace(/=$/, "");

  // Generate the newly updated url.
  return `${pathname}${Array.from(paramStr).length > 0 ? `?${paramStr}` : ""}`;
};

/**
 * Builds a list of modified Urls with the provided params key and values,
 * preserving any search params provided in the original url.Optionally
 * you can pass and object used to set static params values.
 * @param {string} url - The base url of the output url set.
 * @param {string} key - The search params for the associated values
 * @param {Array} values - The search param values
 * @param {object} extraParams - A key values pairing used to add static search param values.
 * @returns {string[]} A list of URLs
 * @example
 * import {buildUrlSet} from '/path/to/utils/url'
 *
 * buildUrlSet(
 *     '/womens/clothing',
 *     'sort',
 *     ['price-high-to-low', 'price-low-to-high'],
 *     {offset: 0}
 * )
 *
 * // Returns
 * // ['/womens/clothing?sort=price-high-to-low', '/womens/clothing?sort=price-low-to-high']
 */
export const buildUrlSet = (
  url = "",
  key = "",
  values = [],
  extraParams = {},
) =>
  values.map(value =>
    rebuildPathWithParams(url, { [key]: value, ...extraParams }),
  );

/**
 * Given a category and the current locale returns an href to the product list page.
 *
 * @param {Object} category
 * @returns {string}
 */
export const categoryUrlBuilder = (category: { id?: string | number }) =>
  encodeURI(`/category/${category.id}`);

/**
 * Given a product and the current locale returns an href to the product detail page.
 *
 * @param {Object} product
 * @returns {string}
 */
export const productUrlBuilder = (product: { id?: string | number }) =>
  encodeURI(`/product/${product.id}`);

/**
 * Given a search term, constructs a search url.
 *
 * @param {string} searchTerm
 * @returns {string}
 */
export const searchUrlBuilder = (searchTerm: string | number | boolean) =>
  "/search?q=" + encodeURIComponent(searchTerm);

/**
 * Generates the URL Template literal (Template string) used to build URLs in the App according
 * the current selected site/locale and the default App URL configuration.
 *
 * @param appConfig Application default configuration.
 * @param siteRef Current selected Site reference. The value can be the Site id or alias.
 * @param localeRef Current selected Locale reference. The value can be the Locale id or alias.
 * @returns {function(*, *, *): string} function providing: path, site and locale generates a URL.
 */
export const createUrlTemplate = (locale: Locale) => {
  const prefix = locale.id !== "default" ? `/${locale.alias || locale.id}` : "";

  return (path: string | URL, locale?: Locale) => {
    const url = new URL(path.toString(), getAppOrigin());
    url.pathname = `${prefix}${url.pathname}`;
    if (locale) {
      const prefix =
        locale.id !== "default" ? `/${locale.alias || locale.id}` : "";
      url.pathname = `${prefix}${url.pathname}`;
    }
    return `${url.pathname}${url.search}${url.hash}`;
  };
};

/*
 * Remove query params from a give url path based on a given list of keys
 *
 * @param {string} path - The part of url to have params removed from.
 * @param {array} keys - list of params to be removed
 * @returns {string} - the url after param has been removed
 * @example
 * import {removeQueryParamsFromPath} from /path/to/util/url
 *
 * removeQueryParamsFromPath(
 *   /en-GB/cart?pid=1234&color=black&size=s&abc=12,
 *   ['pid', 'color', 'size']
 * )
 * // returns
 * // '/en-GB/cart?abc=12'
 */
export const removeQueryParamsFromPath = (path: string, keys: string[]) => {
  const [pathname, search] = path.split("?");
  const params = new URLSearchParams(search);
  keys.forEach(key => {
    if (params.has(key)) {
      params.delete(key);
    }
  });

  // Clean up any trailing `=` for params without values.
  const paramStr = params.toString().replace(/=&/g, "&").replace(/=$/, "");

  return `${pathname}${paramStr && "?"}${paramStr}`;
};
