import { useCallback } from "react";
import { namedAction } from "remix-utils/named-action";
import { z } from "zod";

import { redirectDocument, useFetcher } from "@remix-run/react";
import { json } from "@remix-run/server-runtime";

import {
  invariantResponseError,
  validationError,
} from "~/components/forms/validationErrorResponse.server";
import { commitSession } from "~/server/sessions.server";

import { withZod } from "@remix-validated-form/with-zod";

import { getCountryByCode, getLocaleByCountry } from "~/lib/countries";
import { createUrlTemplate } from "~/lib/utils/url";
import { invariant } from "~/lib/utils/utils";

const Schema = z.object({
  action: z.enum(["setCountry"]),
  countryCode: z.string(),
});
const SetCountrValidator = withZod(Schema);

export const action = async ({ request, context }: ActionArgs) => {
  const session = context.session;
  const formData = await request.clone().formData();
  const validation = await SetCountrValidator.validate(formData);
  if (validation.error) {
    throw validationError(validation.error);
  }

  return namedAction(formData, {
    async setCountry() {
      const { locale } = context;
      const countryCode = validation.data.countryCode;
      const country = getCountryByCode(countryCode);

      invariantResponseError(country, "Invalid country code!");
      session.set("preferredCountryCode", country.countryCode);

      const targetLocale = country.locale;
      if (targetLocale !== locale.id || targetLocale !== locale.alias) {
        /* move customer to new locale */
        const [locale, site] = getLocaleByCountry(countryCode);
        invariant(site, `Site not found for country code ${countryCode}!`);
        invariant(locale, `Locale not found for country code ${countryCode}!`);

        throw redirectDocument(createUrlTemplate(locale)("/"), {
          headers: {
            "Set-Cookie": await commitSession(),
          },
        });
      }

      return json({ country });
    },
  });
};

export const useSetCountry = () => {
  const fetcher = useFetcher();
  const setCountry = useCallback(
    (countryCode: string) => {
      return fetcher.submit(
        {
          action: "setCountry",
          countryCode,
        },
        {
          method: "POST",
          action: "/resources/country",
        },
      );
    },
    [fetcher],
  );
  return { setCountry, fetcher };
};
